.stats-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 0 2%;
  margin-bottom: 5%;
}

/* .dropbtn {
  background-color: #04aa6d;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
} */

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color:#fcdcdd;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: medium;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #FFC0CB;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #4e3e8e;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.polar-area-chart {
  width: 100%;
  height: 100%;
}

.pie-area-chart {
  width: 100%;
  height: 100%;
}

.polar-area-chart {
  width: 100%; /* To make the chart container responsive */
  max-width: 500px;
  height: auto; /* To maintain aspect ratio */
}

.pie-area-chart {
  width: 100%; /* To make the chart container responsive */
  max-width: 500px; /* Set the maximum width for the chart */
  height: auto; /* To maintain aspect ratio */
}

.dropdown-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* You can add additional styles if needed */
}

.custom-container {
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 70%; /* Set the desired width */
  height: 70%; /* Set the desired height */
  margin: 0 auto; /* Center the container horizontally if it has a defined width */ 
}

.custom-container-mobile {
  height: 600px; 
}

.title-font {
  font-family: "Blinker", sans-serif;
  font-size: xx-large;
  text-align: justify;
  font-weight: bold;
}

.desc-font {
  font-family: "Blinker", sans-serif;
  font-size: medium;
  text-align: justify;
  font-style: italic;
}