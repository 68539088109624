.user-profile-selection {
    text-align: center;
    font-family: "Blinker", sans-serif;
    max-width: 500px;
    margin-top: 30px;
  }
  
  .profile-icons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
  }
  
  .profile-icon {
    cursor: pointer;
    border-radius: 50%;
    /* overflow: hidden; */
    width: 100px; /* Adjust width/height of the circular icons */
    height: 100px; /* Adjust width/height of the circular icons */
  }
  
  .profile-icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .profile-icon.img.selected {
    border: 4px solid #f9a9ad;
    transform: scale(1.2) rotate(10deg); /* Scales to 110% and rotates 10 degrees */
    z-index: 1; /* Ensure the scaled image appears above the others */
    transition: transform 0.3s ease; /* Smooth transition for transform */
}
  
  .add-profile-adult-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px; /* Adjust width and height for the plus icon container */
    height: 100px;
    border-radius: 50%; /* To make it circular */
    background-color: #f9a9ad; /* Background color for the icon container */
    cursor: pointer;
  }

  .add-profile-child-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px; /* Adjust width and height for the plus icon container */
    height: 100px;
    border-radius: 50%; /* To make it circular */
    background-color: bisque; /* Background color for the icon container */
    cursor: pointer;
  }
  
  /* profileSelection.css */
  
  /* Styles for the modal */
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
    max-width: 80%;
    width: 300px;
    overflow: auto;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
  }
  
  .pin-input {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .pin-input input {
    width: calc(25% - 10px);
    padding: 10px;
    text-align: center;
    font-size: 18px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 5px;
  }
  
  .pin-input input:last-child {
    margin-right: 0;
  }
  
  /* Other styles for consistency */
  .user-profile-selection {
    text-align: center;
  }
  
  p {
    font-size: 35px;
    font-family: "Blinker", sans-serif;
    /* margin-bottom: 10px; */
  }
  
  .manage-profiles {
    margin-top: 50px;
  }
 