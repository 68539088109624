.rightpart {
  width: 87%;
  float: right;
  /* background: linear-gradient(to top left, whitesmoke, lightyellow); */
}

@media only screen and (max-width: 768px) {
  .rightpart {
    width: 100%;
  }
}

.userProfile-page {
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 50px;
  font-family: "Open Sans", sans-serif;
  border: 2px solid #f9a9ad;
  border-radius: 10px;
  margin: 0 auto;
  /* margin-top: 40px;
  margin-bottom: 40px; */
}

.childProfile-page {
  max-width: 800px;
  height: 900px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 60px 50px;
  font-family: "Open Sans", sans-serif;
  border: 2px solid #f9a9ad;
  border-radius: 10px;
  margin: 0 auto;
  /* margin-top: 40px;
  margin-bottom: 40px; */
}

.user-profile-grid1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  /* justify-items: center;
  justify-content: center; */
}

.user-profile-font {
  font-family: "Blinker", sans-serif;
  font-size: x-large;
  font-weight: bold;
}

.user-font {
  font-family: "Blinker", sans-serif;
  font-size: larger;
}

.user-profile-image {
  background-image: url("../images/graphics/userprofile.jpg");
  height: 100vh;
  width: 100%;
}

.upline-field {
  border: none;
  padding: 5px;
  border-bottom: 2px solid pink;
  font-size: 16px;
  /* width: 100%; */
  margin-bottom: 8px;
}

.s-field {
  border: none;
  border-bottom: 2px solid pink;
  background-color: transparent;
}

.inactive-button {
  color: 2px solid pink ; /* Change text color for the inactive state */
  cursor: not-allowed; /* Change cursor style to indicate it's not clickable */
  /* You can add other styles to indicate it's disabled */
  pointer-events: none; /* Disable pointer events to remove hover effect */
  opacity: 0.6; /* Reduce opacity to make it appear faded */

}

