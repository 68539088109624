.card-container {
    width: 18rem;
    border-radius: 1rem;
    box-shadow: 0px 10px 8px #999;
    display: flex;
    flex-direction: column;
    margin: 0.5rem;
    transition: background-color 0.3s ease; /* Smooth transition for color change */
    height: fit-content;

    transition: transform 0.3s ease; /* Add transition for smooth scaling */
    border: 1px solid #26755e; /* Set border width, style, and color */

  }
  
  .card-img {
    width: 100%;
    border-radius: 1rem 1rem 0 0;
  }
  
  .card-title {
    margin: 0.5rem 5%;
    font-size: x-large;
    text-align: center;
    border-bottom: 2px solid  #26755e; /* Adjust the thickness, style, and color as needed */
    padding-bottom: 4px; /* Optional: add some space between text and line */
    display: inline; /* Ensure the text stays inline */
    
  }

  .card-subtitle {
    font-size: large;
    margin: 0 0 16px;
    color: #26755e; /* Correct property for setting text color */
    text-align: center;
  }
  
  
  .card-description {
    margin: 0.5rem 5%;
    font-size: medium;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Limit to 4 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis when content overflows */
  line-height: 1.5; /* Adjust as needed for line height */

  }
  
  .card-btn {
    text-align: center;
    width: 90%;
    border-radius: 5px;
    /* padding: 0.5rem 1rem; */
    background-color: #26755e; /* Teal color */
    color: white;
    text-decoration: none;
    margin: 0.5rem 0.5rem;
  }

  .card-container:hover {
    box-shadow: 0px 12px 10px #574b31;
    background-color: #569e8a91; /* Teal color */
}
