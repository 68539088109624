.Game-container {
    background: linear-gradient(to top left, whitesmoke, lightyellow);
    height: 100vh;
    width: 87%;
    float: right;
}

@media only screen and (max-width: 1350px) {
    .Game-container {
      width: 82%;
    }
  }
  
  @media only screen and (max-width: 1100px) {
    .Game-container {
      width: 78%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .Game-container {
      width: 100%;
    }
  }

.digitamiLogo {
    text-align: center;

}

.digitamiImg {
    width: 200px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
}

.windowsImg {
    width: 100px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
}

.androidImg {
    width: 100px; /* Adjust the width as needed */
    height: auto; /* Maintain aspect ratio */
}

.centerRow {
display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.centerRow div {
    margin: 0 50px;
}