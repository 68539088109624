.material-page{
    width: 87%;
    float: right;
    /* background: linear-gradient(to top left, whitesmoke, lightyellow); */
        display: flex;
        align-items: center;
        flex-direction: column;

} 

@media only screen and (max-width: 1350px) {
    .material-page {
      width: 82%;
    }
  }
  
  @media only screen and (max-width: 1100px) {
    .material-page {
      width: 78%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .material-page {
      width: 100%;
    }
  }
 

.card-page {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    
    display: grid;
    align-items: center;
    justify-content: center;
}
  
  .col11 {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
   

   /*.material-page {
    width: 87%;
    float: right;
    background-color: #faf9f6;
    padding: 20px;
}

.card-page {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    justify-content: center;
    align-items: start;
}

.col11 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
*/