.modalBackground {
    width: 100vw;
    height: 100vh;
    background-color: rgba(200, 200, 200);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContainer {
    width: 500px;
    height: 500px;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0 ,0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
}

.modalContainer .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.titleCloseBtn button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
}

.title {
    text-align: center;
    font-family: "Blinker", sans-serif;
    font-size: x-large;
    font-weight: bold;
}

.body {
    margin-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.body2 {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    padding-left: 70px;
    padding-right: 70px;
}

.switch {
    width: 100px;
    height: 60px;
    background-color: rgba(221, 22, 22, 0.4);
    display: flex;
    justify-content: flex-start;
    border-radius: 50px;
    padding: 10px;
    cursor: pointer;
  }
  
  .switch[data-isOn="true"] {
    justify-content: flex-end;
  }
  
  .handle {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 40px;
  }

