.sidebar {
  position: fixed;
  top: 0;
  left: -1000px;
  width: 13%;
  /* min-width: 20%; */
  height: 100vh;
  /* background-color: #393e46; */
  background-color: #34373a;
  color: #f4f5f5;
  overflow-y: auto;
  transition: left 0.5s;
  /* cursor: pointer; */
  z-index: 200;
}

.sidebar.open {
  left: 0;
}

.sidebar-toggle {
  position: fixed;
  top: 20px;
  left: 20px;
  cursor: pointer;
  color: #f9a9ad;
  z-index: 999;
}

@media only screen and (min-width: 768px) {
  .sidebar {
    left: 0;
  }

  .sidebar-toggle {
    display: none;
  }
}

@media only screen and (max-width: 1350px) {
  .sidebar {
    width: 18%;
  }


}

@media only screen and (max-width: 1100px) {
  .sidebar {
    width: 22%;
  }


}

@media only screen and (max-width: 768px) {
  .sidebar {
    width: 100%;
  }
  .menu-options-container {
    text-align: center;
  }
  .social-icons {
    margin-bottom: 50%;
  }
  .expand-sidebar {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgb(57, 62, 70, 0.4);
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.logo-container {
  padding: 1px;
  text-align: center;
}

.logo {
  width: 100%;
}

.menu-options-container {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
}

.profile-container {
  display: flex;
    align-items: center;
    justify-content: center;
}

.icon {
    width: 30%;
    height: 30%;
    object-fit: cover;
}

.icon img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Change to 'contain' if you don't want the image cropped */
}

.text {
  color: #fff0ba;
  font-family: "Blinker", sans-serif;
  font-size: medium;
  width: 60%;
  height: 60%;
  padding-left: 10px;
  
}

.menu-option {
  color: #fff0ba;
  /* padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px; */
  padding: 10px;
  text-decoration: none;
  display: block;
  font-weight: bold;
  font-size: x-large;
  cursor: pointer;
}

.menu-option:hover {
  cursor: pointer;
  background-color: #6c6868;
  /* border: 1px solid #6c6868; */
  color: #fff0ba;
  border-radius: 5px;
}

.sign-out-button {
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #fff0ba;
}

.menu-option.selected {
  background-color: #6c6868;
  /* border: 1px solid #6c6868; */
  border-radius: 5px;
}

.dropdown {
  font-family: "Blinker", sans-serif;
  font-size: x-large;
  margin-top: 0%;
  margin-bottom: 0%;
}

.dropdown:hover {
  cursor: pointer;
  background-color: #6c6868;
  /* border: 1px solid #6c6868; */
  color: #fff0ba;
  border-radius: 5px;
}

.dropdown.selected {
  background-color: #6c6868;
  /* border: 1px solid #6c6868; */
  border-radius: 5px; 
}

.btn.btn-link.dropdown-toggle.selected {
  background-color: #6c6868;
  /* border: 1px solid #6c6868; */
  border-radius: 5px; 
}

/* 
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 200px;
  height: 100%;
  background-color: #393e46;
  color: #f4f5f5;
  overflow-y: auto;
  transition: width 0.5s;
}

@media only screen and (max-width: 767px) {
  .sidebar {
    width: 60px;
  }

  .logo-container {
    display: none;
  }

  .menu-options-container {
    display: none;
  }

  .sign-out-button {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .sidebar {
    width: 200px;
  }

  .logo-container {
    display: block;
  }

  .menu-options-container {
    display: block;
  }

  .sign-out-button {
    display: block;
  }
}

.logo-container {
  padding: 1px;
  text-align: center;
}

.logo {
  width: 100%;
}

.menu-options-container {
  padding: 20px;
}

.menu-option {
  color: #fff0ba;
  padding: 10px;
  text-decoration: none;
  display: block;
  font-weight: bold;
  font-size: larger;
}

.menu-option:hover {
  cursor: pointer;
  background-color: #6c6868;
  border: 1px solid #6c6868;
  color: #fff0ba;
  border-radius: 5px;
}

.sign-out-button {
  top: 20px;
  right: 20px;
  cursor: pointer;
  color: #fff0ba;
} */
