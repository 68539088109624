.topic-rightpart {
    width: 87%;
    float: right;
    background: linear-gradient(to bottom , rgb(133, 177, 164), rgb(255, 255, 255));

  }
  
  
  @media only screen and (max-width: 1350px) {
    .topic-rightpart {
      width: 82%;
    }
  }
  
  @media only screen and (max-width: 1100px) {
    .topic-rightpart {
      width: 78%;
    }
  }

  @media only screen and (max-width: 768px) {
    .topic-rightpart {
      width: 100%;
    }
  }
  
  .Topic-Page {
    max-width: 800px;
    text-align: center;
    background-color: rgba(255, 255, 255, 1);
    padding: 50px;
    font-family: "Open Sans", sans-serif;
    border: 2px solid #26755e;
    border-radius: 10px;
    margin: 0 auto;

    /* margin-top: 40px;
      margin-bottom: 40px; */
  }
  
  .Topic-Page h1 {
    font-size: 42px;
    /* margin-bottom: 10px; */
    color: #393e46;
    border-bottom: 2px solid  #26755e; /* Adjust the thickness, style, and color as needed */
    ;
    
  }
  .Topic-Page h3 {
    font-size: 30px;
    color: #393e46; 
  }
  
  .Topic-Page p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  .Topic-Page ul {
    text-align: left;
    margin-left: 30px;
    margin-bottom: 20px;
  }
  
  .Topic-Page li {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 5px;
  }
  
  .TopicDescription-font {
    font-family: "Blinker", sans-serif;
    font-size: larger;
    text-align: justify;

    white-space: pre-wrap;

  }
  
  .AboutPage-page-container {
    padding-bottom: 100px; /* adjust the value as needed */
  }
  
  /* Add this to your existing CSS file or in a style block */
.image-row {
  display: flex; /* Use flexbox to arrange images in a row */
  justify-content: center; /* Center the images */
  gap: 10px; /* Space between images */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  margin-top: 20px; /* Add some margin on top */
}

.image-row img {
  max-width: 20%; /* Set each image width to 30% */
  height: auto; /* Maintain the aspect ratio */
}


.image-rowTopic5 img {
  max-width: 12%; /* Set each image width to 30% */
  height: auto; /* Maintain the aspect ratio */
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .image-row img {
    max-width: 35%; /* Adjust width on smaller screens */
  }


}

  .header-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position:relative;
    margin-top: 0px; /* Adjust as needed */
  }
  
  .title-container {
    text-align: center;
    flex-grow: 1; /* Ensures the title container takes the remaining space */
  }
  
  .prev-arrow,
  .next-arrow,
  .back-arrow {
    background-color: #81b6a7;
    border:solid;
    border-color: #26755e;
    padding: 10px 15px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50px;
    margin-left: 7%;
    margin-right: 7%;
    position: relative;
    z-index: 10; /* Keeps them above other elements */
    color: #ffffff;
  }

  button:disabled {
  background-color: #81b6a7; /* Change background color */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
  opacity: 0.5; /* Make it semi-transparent */
  border:solid;
  border-color: #81b6a7;
}
