.form {
    position:relative;
    max-width: 800px;
    width:100%;
    /* height: 100vh;
    min-height: 1030px; */
    background-color: rgba(255, 255, 255, 0.9);
    padding: 50px;
    font-family: "Open Sans", sans-serif;
    border: 2px solid #f9a9ad;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0 ,0.35) 0px 5px 15px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.page-display {
    padding-left: 20px;
    padding-right: 20px;
}

.section-display {
    margin-top: 15px;
}

.ruser-profile-font2 {
  font-family: "Blinker", sans-serif;
  font-size: x-large;
  font-weight: bold;
}

.ruser-profile-font {
    margin-top: 20px;
    font-family: "Blinker", sans-serif;
    font-size: x-large;
    font-weight: bold;
  }
  
.ruser-font {
    font-family: "Blinker", sans-serif;
    font-size: larger;
}

.ruser-font-v {
    font-family: "Blinker", sans-serif;
    font-style: italic;
    font-size: large;
}

.attention {
  font-family: "Blinker", sans-serif;
    font-style: italic;
    font-size: large;
    color: red;
}
  
  /* .ruser-profile-image {
    background-image: url("../images/graphics/userprofile.jpg");
    height: 100vh;
    width: 100%;
  } */

.upline-field {
    border: none;
    padding: 5px;
    border-bottom: 2px solid pink;
    font-size: 16px;
    max-width: 25%;
    margin-bottom: 8px;
    background-color: rgba(255, 255, 255, 0);
}

.upline-field2 {
  border: none;
  padding: 5px;
  border: 2px solid pink;
  font-size: 16px;
  max-width: 35%;
  margin-bottom: 8px;
  background-color: rgba(255, 255, 255, 0);
}

.nav-bar {
  display: flex;
  justify-content: space-between; 
  position: absolute;
  bottom: 0;
  left: 0;   
  right: 0;  
  margin-bottom: 15px;
  padding: 0 50px; 

}

.form-button {
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: #f3606f;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
}

.form-inactive-button {
  cursor: not-allowed; 
  pointer-events: none; 
  border-radius: 7px;
  width: 100px;
  height: 40px;
  background-color: #f9a9ad;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 5px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px;
  margin: 5px;
}

/* PROGRESS BAR */
.progressbar {
    width: 100%;
    height: 10px;
    background-color: whitesmoke;
    margin-bottom: 50px;
    border-radius: 5px;
  }
  
.progressbar div {
    border-radius: 5px;
    width: 33.3%;
    height: 100%;
    background-color: #f5646b;
}

.form-body {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.body-context {
  font-family: "Blinker", sans-serif;
  font-size: large;
  padding: 12px;
}

/* .switch {
  width: 10%;
  height: 10%;
  background-color: #f3606f;
  display: flex;
  justify-content: flex-start;
  border-radius: 50px;
  padding: 10px;
  cursor: pointer;
} */

.switch[data-isOn="true"] {
  justify-content: flex-end;
}

.handle {
  width: 30px;
  height: 30px;
  background-color: white;
  border-radius: 40px;
}

.results-list {
  max-width: 300px;
  background-color: lightgoldenrodyellow;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 8px #ddd;
  padding-left: 10px;
}