.ruser-profile-font {
  font-family: "Blinker", sans-serif;
  font-size: x-large;
  font-weight: bold;
}

.ruser-font {
  font-family: "Blinker", sans-serif;
  font-size: larger;
}

.ruser-profile-image {
  background-image: url("../images/graphics/userprofile.jpg");
  height: 100vh;
  width: 100%;
}

.user-page {
  max-width: 800px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 50px;
  font-family: "Open Sans", sans-serif;
  border: 2px solid #f9a9ad;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0 ,0.35) 0px 5px 15px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.new-user-profile-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  justify-items: center;
  justify-content: center;
  margin-top: 2%;
  margin-bottom: 3%;
}

.user-profile-font {
  font-family: "Blinker", sans-serif;
  font-size: x-large;
  font-weight: bold;
}

.user-font {
  font-family: "Blinker", sans-serif;
  font-size: larger;
}

.user-profile-image {
  background-image: url("../images/graphics/userprofile.jpg");
  width: 100%;
}

.upline-field {
  border: none;
  padding: 5px;
  border-bottom: 2px solid pink;
  font-size: 16px;
  width: 85%;
  margin-bottom: 8px;
  background-color: rgba(255, 255, 255, 0);
}